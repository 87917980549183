<template>
  <div class="drawer-content">
    <div class="drawer-content__top">
      <slot name="header"></slot>
      <slot name="filter"></slot>
    </div>

    <div class="drawer-content__list j-drawer-content__list">
      <slot name="list"></slot>
      <span
        v-if="showBackBtn"
        v-expose="{ id: '1-8-1-56' }"
        v-tap="{ id: '1-8-1-57' }"
        class="back-to-top"
        :style="{ 'bottom': footerHeight + 'px' }"
        @click="backToTop"
      ><i class="iconfont icon-top1"></i></span>
    </div>
    <div class="drawer-content__footer j-drawer-content__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent, nextTick } from 'vue'
import { throttle } from '@shein/common-function'
export default defineComponent({
  name: 'AddOnContent',
  directives: {
    expose,
    tap,
  },
  data() {
    return {
      containerDom: null,
      footerHeight: 0,
      showBackBtn: false,
    }
  },
  mounted() {
    nextTick(() => {
      this.containerDom = document.querySelector('.j-drawer-content__list')
      this.containerDom.addEventListener('scroll', this.handleScroll)
    })
  },
  beforeUnmount() {
    this.containerDom.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll: throttle({
      func: function() {
        if (this.containerDom?.scrollTop > this.containerDom?.clientHeight) {
          this.footerHeight = document.querySelector('.drawer-content__footer')?.clientHeight + 16
          this.showBackBtn = true
        } else {
          this.showBackBtn = false
        }
      },
      wait: 100,
      options: {
        leading: true,
        trailing: true
      }
    }),
    backToTop() {
      this.containerDom.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
})
</script>

<style lang="less" scoped>
.drawer-content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__top {
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 10;
  }
  &__list {
    flex: 1;
    overflow-y: scroll;
    // 下拉全屏收起 需要禁用 过度滚动行为
    overscroll-behavior: none;
    background-color: #fff;
    .back-to-top {
      position: fixed;
      z-index: 2;
      right: 32/75rem;
      width: 72/75rem;
      height: 72/75rem;
      line-height: 72/75rem;
      border-radius: 100%;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      background-color: #fff;
      color: #767676;
      animation: fadeUp 250ms;
      text-align: center;
      .icon-top1 {
        font-size: 20px;
      }
    }
  }
  &__footer {
    position: relative;
    bottom: 0;
    width: 100%;
    flex: 0;
    background-color: #fff;
  }
}
@keyframes fadeUp {
	from {
		opacity: 0;
		transform: scale(0);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}	
}
</style>
