
<template>
  <div class="cart-addItem__footer">
    <div class="cart-addItem__footer-desc">
      <div class="cart-addItem__footer-wrapper">
        <i
          v-if="progressRate == 100"
          class="suiiconfont sui_icon_selected_16px"
        ></i>
        <p 
          class="cart-addItem__footer-tip"
          v-html="tips"
        ></p>
      </div>
      <LineProgress
        v-if="progressRate < 100"
        :value="progressRate"
        :color="'#222'"
        bgColor="#bbb"
      />
    </div>

    <s-button
      :type="['H48PX']"
      class="cart-addItem__footer-btn"
      @click="handleBack"
    >
      {{ language.SHEIN_KEY_PWA_24819 }} 
    </s-button>
  </div>
</template>

<script>
import { SButton } from '@shein-aidc/sui-button/mobile'
import { defineComponent } from 'vue'
import LineProgress from './LineProgress.vue'

export default defineComponent({
  components: {
    LineProgress,
    SButton,
  },
  emits: ['back'],
  props: {
    couponItem: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
    }
  },
  computed: {
    tips() {
      return this.couponItem?.progressTip
    },
    progressRate() {
      const { meetHigh, thresholds } = this.couponItem || {}
      if (meetHigh == 1) return 100 // 已满足最高档

      const thresholdItem = thresholds?.find(v => v.progressPercent >= 0 && v.progressPercent < 1)
      return (thresholdItem?.progressPercent || 0) * 100
    },
  },
  methods: {
    handleBack() {
      this.$emit('back')
    },
  },
})
</script>

<style lang="less">
.cart-addItem__footer {
  width: 100%;
  .border-dpr(border-top, 1px, #E5E5E5);
  padding: 0.32rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .cart-addItem__footer-btn {
    flex-shrink: 0;
  }
}

.cart-addItem__footer-desc {
  flex-grow: 1;
  margin-right: 24/75rem;
  color: #999;
}
.cart-addItem__footer-tip {
  .font-dpr(20px);
  color: @sui_color_gray_dark1;
  em {
    color: @sui_color_highlight;
    font-weight: normal;
  }

  word-break: break-all;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.cart-addItem__footer-wrapper {
  display: flex;
  align-items: center;
  color: #222;
  margin-bottom: 8/75rem;
  i {
    .margin-r(8px);
  }
}
</style>
