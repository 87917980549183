<template>
  <div 
    class="progress-bar j-progress-bar"
    :style="style"
  >
    <div
      ref="progressBar"
      class="progress-bar-bg"
      :class="{
        'is-animation': animation,
        'is-transparent': transparent,
      }"
    >
    </div>
    <template v-if="showCircle">
      <CircleIcon
        v-for="(item, index) in thresholds"
        :key="index"
        class="ico"
        :style="{
          [GB_cssRight ? 'left' : 'right']: `${100/thresholds.length * (thresholds.length - index - 1)}%`
        }"
        :complete="isComplete(item)"
        :opacity="isOpacity(index)"
        :size="circleSize"
        :icon-style="{
          iconColor: barStyle.iconColor,
        }"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent, nextTick, defineAsyncComponent } from 'vue'
import { gsap } from 'gsap'
import { EventProgressUpdate } from 'public/src/pages/common/add-on/utils/event.js'
const { GB_cssRight } = gbCommonInfo

const FIRST_RANGE_BORDER = '0.5px solid #FF5F1B'
const FIRST_RANGE_BG = 'repeating-linear-gradient(115deg, #FF8F3F 0%, #FF8F3F 1%, #FF772B 1%, #FF772B 2%)'
const MULTIPLE_RANGE_BORDER = '0.5px solid #E6433E'
const MULTIPLE_RANGE_BG = 'repeating-linear-gradient(115deg, #FB5F56 0%, #FB5F56 1%, #E03C38 1%, #E03C38 2%)'
const DEFAULT_HEIGHT = 12

const PROGRESS_TYPE = {
  single: 'SINGLE',
  multiple: 'MULTIPLE',
}

export default defineComponent({
  components: {
    CircleIcon: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/CircleIcon.vue')),
  },
  props: {
    thresholds: { 
      type: Array,
      default: () => ([])
    },
    animation: { // 是否无限滚动动画
      type: Boolean,
      default: false,
    },
    transparent: { // 是否渐变效果
      type: Boolean,
      default: false,
    },
    autoUpdate: { // 数据变化自动更新进度动画
      type: Boolean,
      default: false,
    },
    styleConfig: { // 外界样式配置
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      GB_cssRight,
      isInit: false,
      curType: '', // 上一次进度条展示类型
      preType: '', // 当前进度条展示类型
      curRangeIndex: null, // 当前凑单档位
    }
  },
  computed: {
    showCircle() {
      return this.curType === PROGRESS_TYPE.multiple
    },
    circleSize() {
      const { progressHeight } = this.styleConfig
      return (progressHeight || DEFAULT_HEIGHT) - 2
    },
    style() {
      const { bottomBg, progressHeight } = this.styleConfig
      const h = (progressHeight || DEFAULT_HEIGHT) / 37.5 + 'rem'
      return {
        '--bottom-bg': bottomBg || '#EDEDED',
        '--progress-height': h,
      }
    },
    barStyle() {
      return {
        FIRST_RANGE_BORDER: this.styleConfig?.FIRST_RANGE_BORDER || FIRST_RANGE_BORDER,
        FIRST_RANGE_BG: this.styleConfig?.FIRST_RANGE_BG || FIRST_RANGE_BG,
        MULTIPLE_RANGE_BORDER: this.styleConfig?.MULTIPLE_RANGE_BORDER || MULTIPLE_RANGE_BORDER,
        MULTIPLE_RANGE_BG: this.styleConfig?.MULTIPLE_RANGE_BG || MULTIPLE_RANGE_BG,
        iconColor: this.styleConfig?.iconColor || '',
      }
    },
  },
  watch: {
    thresholds: {
      handler() {
        if (!this.autoUpdate) return
        this.startProgressAnimation()
      },
      deep: true,
    }
  },
  mounted() {
    this.setUpdateEvent()
    this.startProgressAnimation()
  },
  beforeUnmount() {
    EventProgressUpdate.remove('')
  },
  methods: {
    setUpdateEvent() {
      if (this.autoUpdate) return
      // 订阅进度更新事件
      EventProgressUpdate.subscribe({
        callback: () => {
          nextTick(() => {
            this.startProgressAnimation()
          })
        }
      })
    },
    startProgressAnimation() {
      if (!this.thresholds?.length) return
      const ele = this.$refs.progressBar

      const { type, index, val, goForward } = this.formatProgress(this.thresholds)
      this.curRangeIndex = index
      this.preType = this.curType
      this.curType = type

      let tl = gsap.timeline({ duration: .1 })
      // 首次进入
      if (!this.isInit) {
        if (type === PROGRESS_TYPE.single) {
          tl.to(ele, { 
            width: `${val}%`,
            border: val > 0 ? this.barStyle.FIRST_RANGE_BORDER : 'none', 
            backgroundImage: this.barStyle.FIRST_RANGE_BG,
            onComplete: () => {
              tl.kill()
            },
          })
        } else {
          tl.to(ele, { 
            width: `${val}%`,
            border: this.barStyle.MULTIPLE_RANGE_BORDER, 
            backgroundImage: this.barStyle.MULTIPLE_RANGE_BG,
            onComplete: () => {
              tl.kill()
            },
          }, '>0.1')
        }
        this.isInit = true
        return
      }

      // 已经初始化，分四种情况
      // a.单 => 单
      if (this.preType === PROGRESS_TYPE.single && this.curType === PROGRESS_TYPE.single) {
        if (goForward) {
          tl.to(ele, { width: '100%' })
            .set(ele, { width: '0%', opacity: 0 }, '>0.3')
            .to(ele, {
              width: `${val}%`,
              border: val > 0 ? this.barStyle.FIRST_RANGE_BORDER : 'none', 
              backgroundImage: this.barStyle.FIRST_RANGE_BG,
              opacity: 1,
              onComplete: () => {
                tl.kill()
              }
            }, '>0.3')
        } else {
          tl.to(ele, { 
            width: `${val}%`,
            border: val > 0 ? this.barStyle.FIRST_RANGE_BORDER : 'none', 
            backgroundImage: this.barStyle.FIRST_RANGE_BG,
            onComplete: () => {
              tl.kill()
            },
          })
        }
      }
      // b.单 => 多 
      if (this.preType === PROGRESS_TYPE.single && this.curType === PROGRESS_TYPE.multiple) {
        tl.to(ele, {  width: '0%', duration: 0.1 })
        tl.to(ele, { 
          width: `${val}%`,
          border: this.barStyle.MULTIPLE_RANGE_BORDER, 
          backgroundImage: this.barStyle.MULTIPLE_RANGE_BG,
          onComplete: () => {
            tl.kill()
          },
        }, '>0.1')
      }
      // c.多 => 单
      if (this.preType === PROGRESS_TYPE.multiple && this.curType === PROGRESS_TYPE.single) {
        tl.to(ele, {  width: '0%', duration: 0.1 })
        tl.to(ele, { 
          width: `${val}%`,
          border: val > 0 ? this.barStyle.FIRST_RANGE_BORDER : 'none', 
          backgroundImage: this.barStyle.FIRST_RANGE_BG,
          onComplete: () => {
            tl.kill()
          },
        }, '>0.1')
      }
      // d.多 => 多
      if (this.preType === PROGRESS_TYPE.multiple && this.curType === PROGRESS_TYPE.multiple) {
        tl.to(ele, { 
          width: `${val}%`,
          border: this.barStyle.MULTIPLE_RANGE_BORDER, 
          backgroundImage: this.barStyle.MULTIPLE_RANGE_BG,
          onComplete: () => {
            tl.kill()
          }
        })
      }
    },
    formatProgress(arr) {
      if (!arr?.length) return null
      let val = 0
      const idx = arr.findIndex(v => v.progressPercent < 1)
      const type = arr.length === 1 
        ? PROGRESS_TYPE.single 
        : (idx !== 0 ? PROGRESS_TYPE.multiple : PROGRESS_TYPE.single )

      if (type === PROGRESS_TYPE.single) {
        val = arr[0]?.progressPercent * 100
      } else {
        val = (arr?.reduce((acc, cur) => {
          return acc + (cur.progressPercent / arr.length)
        }, 0) || 0) * 100
      }
      return {
        type: type, // 进度条类型
        index: idx, // 正在凑单档位
        val, // 进度值

        goForward: type === PROGRESS_TYPE.single && arr[0]?.transition?.goForward
      }
    },
    isComplete(item) { // 是否已满足
      return item.progressPercent == 1
    },
    isOpacity(index) { // 是否透明状态
      const lastIdx = this.thresholds?.findLastIndex(v => v.progressPercent == 1)
      return index !== lastIdx
    },
  },
})
</script>

<style lang="less" scoped>
@BorderRadius: 12/75rem;

.progress-bar {
  position: relative;
  width: 100%;
  height: var(--progress-height);
  background: var(--bottom-bg);
  border-radius: @BorderRadius;
  overflow: hidden;
  box-shadow: var(--box-shadow, 0px 0px 2px 0px #CCC inset);

  .progress-bar-bg {
    position: relative;
    box-sizing: border-box;
    height: var(--progress-height);
    border-radius: @BorderRadius;
    background-size: 450/37.5rem;
    max-width: 100%;
    width: 0%;
    &.is-animation {
      animation: slider-ltr 16s linear infinite /* rtl: slider-rtl 16s linear infinite */;
      transition: width .3s linear;
    }
    &.is-transparent::before {
      content: '';
      border-radius: @BorderRadius;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to left, transparent, rgba(255, 255, 255, .2));
    }
  }

  .ico {
    position: absolute;
    top: 1/37.5rem;
  }
}

@keyframes slider-ltr {
  0% { background-position-x: 100%; }
}
@keyframes slider-rtl {
  0% { background-position-x: -100%; }
}
</style>
