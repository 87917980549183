<template>
  <div class="coupon-normal-foot">
    <div class="left">
      <p 
        class="tips"
        v-html="tips"
      ></p>
      <ProgressBar
        v-if="couponItem"
        transparent
        animation
        :thresholds="couponItem.thresholds"
      />
    </div>
    <div class="right">
      <s-button
        :type="['H80PX', 'primary']"
        class="cart-btn"
        :max-width="(260/75).toFixed(5)"
        :class="{
          'scan-lights': overFirstRange,
        }"
        @click="handleBack"
      >
        {{ couponInfo.goToCheckoutTip }}
      </s-button>
      <IncentivePoint
        v-show="!config.isCloseIncentivePoint"
        class="coupon-normal-foot__incentive-point"
        :tags="tags"
        :bubble="bubble"
      />
    </div>
  </div>
</template>

<script>
import { SButton } from '@shein-aidc/sui-button/mobile'
import { computed, defineComponent, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import incentivePoint from 'public/src/pages/common/add-on/mixins/incentivePoint.js'

export default defineComponent({
  name: 'CouponNormalFoot',
  components: {
    ProgressBar: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/ProgressBar.vue')),
    SButton,
  },
  mixins: [incentivePoint],
  emits: ['back', 'close'],
  props: {
    couponItem: {
      type: Object,
      default: () => ({})
    },
    couponInfo: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    addItemType: {
      type: [String, Number],
      default: '',
    },
  },
  setup() {
    const { state } = useStore()
    const abtInfo = computed(() => state.abtInfo) // incentivePoint 需要
    return {
      abtInfo, // incentivePoint 需要
    }
  },
  computed: {
    tips() {
      return this.couponItem.progressTip
    },
    overFirstRange() {
      return this.couponItem.meetLow == 1
    },
    isShipCoupon() {
      return ['5', '6', '9'].includes(this.couponItem?.apply_for)
    },
  },

  methods: {
    handleBack() {
      this.$emit('back')
    },
  },
})
</script>

<style lang="less" scoped>
.coupon-normal-foot {
  width: 100%;
  padding: 12/37.5rem;
  background: #fff;
  border-top: 1px solid #EAEAEA;
  display: flex;

  .left {
    flex-grow: 1;
    margin-right: 6/37.5rem;
    .tips {
      margin-bottom: 4/37.5rem;
      font-size: 12px;
      line-height: 14/37.5rem;
      color: @sui_color_gray_dark1;
      word-break: break-all;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      :deep(em) {
        font-weight: bold;
        color: #FF6D2A;
      }
    }
  }
  .right {
    position: relative;
    .cart-btn {
      padding: 0 12/37.5rem;
    }
    .free-ship {
      .font-dpr(18px);
      line-height: 12px;
      position: absolute;
      height: 14/37.5rem;
      right: 0;
      top: -10/37.5rem;;
      padding: 0 4/37.5rem;
      border-radius: 20/75rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border: 1px solid #fff;
      background: @sui_color_safety;
    }
    .free-ico {
      margin-right: 2/37.5rem;
      transform: rotateY(0deg)/* rtl: rotateY(180deg) */;
    }
  }

  .scan-lights {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to right, transparent, rgba(255,255,255,.6), transparent);
      animation: ScanLights 6s linear infinite;
    }
  }

  &__incentive-point {
    position: absolute;
    right: 0;
    bottom: calc(100% - 0.05333333rem);
    z-index: @zindex-hack;
  }
}
@keyframes ScanLights {
  0% {
      transform: translate(-100%);
  }
  10% {
      transform: translate(100%);
  }
  100% {
      transform: translate(100%);
  }
}
</style>
