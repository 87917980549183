<template>
  <Teleport to="body">
    <transition
      :name="transitionName"
      @after-enter="afterEnter"
    >
      <div
        v-if="show"
        class="footer-tips"
        :style="style"
      >
        <div class="footer-tips__box">
          <div
            class="footer-tips__text"
            v-html="text"
          ></div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { EventCloseFooterTips } from 'public/src/pages/common/add-on/utils/event.js'
export default defineComponent({
  data() {
    return {
      show: false,
      transitionName: '',
      text: '',
      style: null,
    }
  },
  mounted() {
    EventCloseFooterTips.subscribe({
      callback: () => {
        this.afterEnter()
      }
    })
  },
  methods: {
    start({ bubbleText }) {
      if (!bubbleText) return
      const res = this.calcStyle()
      if (!res) return
      nextTick(() => {
        this.text = bubbleText
        this.transitionName = 'footer-tips-jump'
        this.show = true
      })
    },
    afterEnter() {
      this.transitionName = ''
      this.show = false
    },
    calcStyle() {
      const target = document.querySelector('.j-add-on-container .drawer-content__footer')
      if (!target) return false
      const rect = target.getBoundingClientRect()
      this.style = {
        bottom: `${window.innerHeight - rect.top + 8}px`
      }
      return true
    },
  }
})
</script>

<style lang="less">
@keyframes footer-tips-jump {
  0% {
    opacity: 1;
    transform: translate(-50%, 50%) scale(0.1);
  }
  15% {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
  }
  32% {
    opacity: 1;
    transform: translate(-50%, 4px) scale(1);
  }
  49% {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
  }
  66% {
    opacity: 1;
    transform: translate(-50%, 4px) scale(1);
  }
  83% {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
  }
  91.5% {
    opacity: 1;
    transform: translate(-50%, 2px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 4px) scale(1);
  }
}

.footer-tips {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 200000;
  left: 50%;
  transform: translateX(-50%);
  width: 351/37.5rem;
  opacity: 0;
  &.footer-tips-jump-enter-active { /* stylelint-disable-line */
    animation: footer-tips-jump 3.5s;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -4px;
    margin-bottom: calc(-4px);
    width: 8px;
    height: 8px;
    transform: rotateZ(45deg);
    background: #f2cb6a;
  }
  &__box {
    padding: 4/37.5rem 8/37.5rem;
    background: linear-gradient(91deg, #F9E775 -1%, #FAAD34 98.7%);
    border-radius: 12/37.5rem;
  }
  &__text {
    font-size: 14/37.5rem;
    font-weight: 600;
    font-family: "SF Pro Display", system-ui;
    color: #532100;
    line-height: normal;
    .line-camp(2)
  }
}
</style>
