<template>
  <div
    v-if="item.__cart_item_data__ && item.items && item.items.length"
    v-expose="getExposeByPitCard(item)"
    class="add-on-cart-item__grid"
    @click="handleClickPitCard(item)"
  >
    <div class="add-on-cart-item__wrapper">
      <div
        v-if="item.multiCardTopTip"
        class="add-on-cart-item__title"
      >
        <img
          v-if="item.multiCardTopIcon"
          :src="item.multiCardTopIcon"
          class="add-on-cart-item__title-icon"
        />
        <span class="add-on-cart-item__title-text">{{ item.multiCardTopTip }}</span>
        <!-- sui_icon_more_right_18px -->
        <span
          style="vertical-align: middle; display: inline-block; line-height: 0; font-size: 14px;"
          class="add-on-cart-item__title-more-right"
        >
          <svg
            style="width: auto; height: 1em;"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.04285 4.40372L7.45706 2.9895L13.4675 8.99991L7.45706 15.0103L6.04285 13.5961L10.639 8.99991L6.04285 4.40372Z"
              fill="#FF6B17"
            />
          </svg>
        </span>
      </div>
      <div class="add-on-cart-item__grid-content">
        <div
          v-for="product in item.items"
          :key="product.cartId"
          class="add-on-product-card"
          @click.stop="handleClickPitItem(product, item)"
        >
          <div class="add-on-product-card__top">
            <CropImageContainer
              :img-src="product.goodsImg"
              fixed-ratio="3-4"
            />
            <div
              v-if="product.productImgBelt"
              class="add-on-product-card__belt"
            >
              <img
                v-if="product.productImgBelt.icon"
                :src="product.productImgBelt.icon"
              />
              <span v-if="product.productImgBelt.text">{{ product.productImgBelt.text }}</span>
            </div>
          </div>
          <div
            v-if="product.priceData && product.priceData.unitPrice && product.priceData.unitPrice.price"
            class="add-on-product-card__price"
            v-html="getHtmlPrice(product.priceData.unitPrice.price)"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="item.__cart_item_data__"
    class="add-on-cart-item__normal"
  >
    <div class="add-on-cart-item__wrapper">
      <div class="add-on-cart-item__header">
        <div
          v-if="item.singleCardLureIcon || item.singleCardLureText"
          class="add-on-cart-item__title"
        >
          <img
            v-if="item.singleCardLureIcon"
            :src="item.singleCardLureIcon"
          />
          <span
            v-if="item.singleCardLureText"
            v-html="item.singleCardLureText"
          ></span>
        </div>
        <div
          v-if="item.singleCardTopTip"
          class="add-on-cart-item__sub"
        >
          {{ item.singleCardTopTip }}
        </div>
      </div>
      <ProductListItem
        :item="getGoodsTransformation(item.productCardData)"
        :index="index"
        :cur-operate-index="curOperateIndex"
        :product-item-config="productItemConfigByCardItem"
        :constant-data="constantData"
        :language="language"
        :style="{
          '--product-card-img-radius': 'none',
        }"
        @cardMounted="inst => handleCardMounted(inst, true)"
        @longClick="handleLongClick"
        @openQuickAdd="data => handleOpenQuickAdd(data, item)"
        @clickItem="data => handleClickItem(data, item)"
      />
    </div>
  </div>
  <ProductListItem
    v-else
    :item="item"
    :index="index"
    :cur-operate-index="curOperateIndex"
    :product-item-config="productItemConfig"
    :constant-data="constantData"
    :language="language"
    @cardMounted="inst => handleCardMounted(inst)"
    @longClick="handleLongClick"
    @openQuickAdd="data => handleOpenQuickAdd(data)"
    @clickItem="data => handleClickItem(data)"
  />
</template>

<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { computed } from 'vue'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'
import CropImageContainer from 'public/src/pages/components/CropImageContainer/CropImageContainer.vue'
import ProductListItem from 'public/src/pages/product_list_v2/components/ProductListItem.vue'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import { getSaGoodsList } from 'public/src/services/eventCenter/analysis/config/components/product-item.js'

const props = defineProps({
  item: {
    required: true,
    type: Object,
  },
  index: {
    required: true,
    type: Number,
  },
  curOperateIndex: {
    required: true,
    type: Number,
  },
  cardConfig: {
    required: true,
    type: Object,
  },
  productItemConfig: {
    required: true,
    type: Object,
  },
  constantData: {
    required: true,
    type: Object,
  },
  language: {
    required: true,
    type: Object,
  },
})

const emit = defineEmits([
  'long-click',
  'open-quick-add',
  'click-item',
])

const productItemConfigByCardItem = computed(() => ({
  ...props.productItemConfig,
  showFeedback: false,
}))

const handleCardMounted = (componetInstance, isPit) => {
  componetInstance.setElAttr()
  if (isPit) {
    componetInstance?.$el.setAttribute('data-recommend-info', 'cart_additems_in_feeds')
  }
}

const handleLongClick = (data) => {
  emit('long-click', data)
}

const handleOpenQuickAdd = (data, item) => {
  emit('open-quick-add', data, item)
}

const handleClickItem = (data, item) => {
  emit('click-item', data, item)
}

const handleClickPitItem = (product, item) => {
  daEventCenterV2.triggerNotice({
    id: 'click_cell_card.comp_add-item',
    extraData: {
      click_content: getGoodsList([product]),
      goods_list: getGoodsList(item.items),
    }
  })
  emit('click-pit-item', product, item)
}

const handleClickPitCard = (item) => {
  daEventCenterV2.triggerNotice({
    id: 'click_cell_card.comp_add-item',
    extraData: {
      click_content: 'cell_head',
      goods_list: getGoodsList(item.items),
    }
  })
  emit('click-pit-card', item)
}

const getExposeByPitCard = (item) => {
  return {
    id: 'expose_cell_card.comp_add-item',
    data: {
      goods_list: getGoodsList(item.items),
    }
  }
}

const getHtmlPrice = (price) => price.amountWithSymbol?.replace(price.priceShowStyle, `<em>${price.priceShowStyle}</em>`)

const getGoodsList = (products) => {
  const res = products.map((product, index) => {
    return getSaGoodsList({
      goodsId: product.goodsId,
      sku: product.goodsSn, // 这里曝光 skc
      spu: product.productRelationID,
      realIndex: products.length === 1 ? props.index + 1 : `${props.index + 1}_${index + 1}`,
      pageNum: '',
      price: product.priceData?.unitPrice?.price.amount,
      mallCode: product.mallCode,
      recommendInfo: 'cart_addcell_in_feeds',
    })
  })
  return res.join(',')
}

const getGoodsTransformation = (item) => {
  return goodsTransformation(item, {
    suggestedSaleType: gbCommonInfo.IS_SUGGESTED,
    language: props.language,
    lang: gbCommonInfo.lang ?? 'us',
    isPaid: !!props.cardConfig?.sheinClubInfo?.isPaid,
  })
}
</script>

<style lang="less">
.add-on-cart-item__normal {
  margin-bottom: 0.16rem;
  width: 4.76rem;
  background: linear-gradient(180deg, #FFD3BA 0%, #FFF 15.01%);
  border-radius: 4px;
  overflow: hidden;

  .add-on-cart-item__wrapper {
    padding: 5/37.5rem;
    background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/09/29/68/172759405992cf281638bf37dc3930cb9920443361.png);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .product-card {
    width: 100% !important;
    margin-bottom: 0 !important;
  }

  .add-on-cart-item__header {
    padding: 6/37.5rem 0 10/37.5rem;
    text-align: center;
  }

  .add-on-cart-item__title {
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    line-height: 13px;
    font-size: 11px;
    font-weight: 700;
    color: #FF6B17;

    > img {
      margin-right: 6px;
      height: 14px;
    }

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .add-on-cart-item__sub {
    line-height: 12px;
    font-size: 10px;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}


.add-on-cart-item__grid {
  margin-bottom: 0.16rem;
  width: 4.76rem;
  background: linear-gradient(180deg, #FFD3BA 0%, #FFF 15.01%);
  border-radius: 4px;
  overflow: hidden;

  .add-on-cart-item__title {
    display: flex;
    align-items: center;
    margin: 5px 0 7px 0;
    padding: 0 1px;
    line-height: 13px;
    font-size: 11px;
    font-weight: 700;
    color: #FF6B17;
    &-icon {
      flex-shrink: 0;
      margin-right: 6px;
      width: 16px;
      height: 16px;
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-more-right {
      flex-shrink: 0;
    }
  }

  .add-on-cart-item__wrapper {
    padding: 5/37.5rem;
    background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/09/29/56/1727597293bd5632e7443894e64300674909875825.png);
    background-size: 61px;
    background-position: right top;
    background-repeat: no-repeat;
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4/37.5rem;
  }
}

.add-on-product-card {
  overflow: hidden;

  &__top {
    position: relative;
  }

  &__belt {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2/37.5rem 5/37.5rem;
    line-height: 1.12;
    text-align: center;
    color: #fff;
    font-size: 10px;
    background-color: rgba(#000, 0.6);

    > img {
      flex-shrink: 0;
      margin-right: 2px;
      width: 12px;
    }

    > span {
      display: -webkit-box;
      -webkit-line-clamp:2;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }
  }

  &__price {
    font-size: 10px;
    color: #FA6338;
    text-align: center;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    em {
      font-size: 14px;
    }
  }
}
</style>
