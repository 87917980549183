<template>
  <div class="coupon-add-head">
    <div class="process-container">
      <div
        class="process-content"
      >
        <div class="process-start process-node">
          <div class="process-top place-holder"></div>
          <div class="process-center">
            <i class="process-icon"></i>
          </div>
          <div class="process-bottom">
            0
          </div>
        </div>
        <template
          v-for="(item, index) in progressList"
          :key="index"
        >
          <div
            class="process-bar"
          >
            <div 
              class="process-bar-bg"
              :style="{ width: `${item.progressPercent * 100}%` }"
            ></div>
          </div>
          <div
            class="process-node"
            :class="{
              'success': item.progressPercent >= 1
            }"
          >
            <div class="process-top">
              <span>{{ item.couponFace }}</span>
            </div>
            <div class="process-center">
              <i
                class="process-icon"
                :class="{
                  'suiiconfont sui_icon_success_24px': item.progressPercent >= 1,
                }"
              ></i>
            </div>
            <div class="process-bottom">
              <span>{{ item.thresholdPrice }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <CloseIcon
      class="close-icon"
      size="14px"
      color="#666"
    />
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
export default defineComponent({
  components: {
    CloseIcon: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/CloseIcon.vue')),
  },
  props: {
    couponItem: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    progressList() {
      return this.couponItem?.thresholds || []
    },
  },
})
</script>

<style lang="less" scoped>
.coupon-add-head {
  padding: 20/37.5rem 12/37.5rem 10/37.5rem;
  overflow: hidden;
  position: relative;
  background: linear-gradient(180deg, #FFE2D5 0%, #FFF3ED 60.94%, #FFFFFF 100%);
  &::before {
    content: ' ';
    display: block;
    height: 100%;
    position: absolute;
    background: url('/pwa_dist/images/cart/sui_img_promo-0a1fc212a4.png') no-repeat center;
    max-height: 154/75rem;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
  }
  .close-icon {
    position: absolute;
    top: 4/37.5rem;
    right: 8/37.5rem;
  }
  .process-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
  }
  .process-container::-webkit-scrollbar {
    display: none;
  }
  .process-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    margin: 6/75rem 44/75rem;
    z-index: 1;
  }
}
.process-node {
  .place-holder {
    height: 26.4/75rem;
  }
  .process-top, .process-bottom {
    font-size: 11px;
    line-height: 1.2;
    height: 26.4/75rem;
    position: relative;
    span {
      position: absolute;
      transform: translateX(-50%);
      max-width: 24vw;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      direction: ltr /*rtl:ltr*/;
    }
  }
  .process-center {
    width: 56/75rem;
    height: 56/75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.process-node:not(.success) {
  color: @sui_color_gray_dark3;
  .process-icon {
    width: 24/75rem;
    height: 24/75rem;
    border-radius: 50%;
    background-color: #BBB;
  }
}
.process-node.success {
  color: @sui_color_discount;
  .process-icon::before {
    color: @sui_color_discount;
    border-radius: 50%;
    font-size: 18px;
  }
}
.process-node.process-start {
  color: @sui_color_discount;
  .process-icon {
    background-color: @sui_color_discount;
  }
}
.process-bar {
  flex: 1;
  height: 4/75rem;
  background-color: #BBB;
  border-radius: 2px;
  min-width: 130/75rem;
  .process-bar-bg {
    height: 100%;
    background-color: @sui_color_discount;
    border-radius: 2px;
    transition: width .5s linear;
  }
}
</style>
