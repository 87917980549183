<template>
  <div class="popover-goods">
    <div 
      v-for="(item, i) in showList"
      :key="i"
      class="goods"
    >
      <img   
        :src="item.goodsImgs"
        width="100%"
        height="100%"
      />
      <div 
        v-if="i == 1"
        class="modal"
      >
        <span v-if="modalText">{{ modalText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PopoverGoods',
  props: {
    supportItemInfo: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {}
  },
  computed: {
    showList() {
      return this.supportItemInfo?.goodInfos || []
    },
    modalText() {
      const length = this.supportItemInfo?.num
      if (length >= 2 && length <= 100) return `+${length - 1}`
      if (length > 100) return '99+'
      return ''
    },
  },
  methods: {
  },
})
</script>

<style lang="less" scoped>
.popover-goods {
  display: flex;
  .goods {
    position: relative;
    width: 38/37.5rem;
    height: 38/37.5rem;
    margin-right: 4/37.5rem;
    .modal {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.70);
      font-size: 12px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
