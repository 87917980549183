<template>
  <div
    :class="[
      'multiple-head',
      page && 'page',
      mini && 'mini',
      componentName === 'PlanA' && 'plan-a',
    ]"
  >
    <div
      v-if="page"
      class="back"
    >
      <i
        class="icon sui_icon_nav_back_24px suiiconfont"
        @click="gotoBack"
      ></i>
    </div>
    <div class="header-content">
      <div class="header-content__left">
        <component
          :is="componentName"
          ref="swiper"
          :page="page"
          :mini="mini"
          :coupon-list="couponInfoList"
          :coupon-info="couponInfo"
          @change-coupon="onChangeCoupon"
        />
      </div>
      <div
        v-if="showCouponMore"
        class="header-content__right"
      >
        <CouponMoreReference
          :page="page"
          :modal="mini || page"
          :language="language"
          :drawer-config="drawerConfig"
          :coupon-list="couponInfoList"
          :capsule-style="capsuleStyle"
          @click-coupon="onClickCoupon"
        />
      </div>
    </div>
  
    <CloseIcon
      v-if="!page"
      class="close-icon"
      size="14px"
      color="#fff"
    />
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
import { daEventCenter } from '@/public/src/services/eventCenter/index.js'
import { getSatisfiedValue } from 'public/src/pages/common/add-on/layout/typeCoupon/utils.js'
import { EventPageBack } from 'public/src/pages/common/add-on/utils/event.js'

export default defineComponent({
  name: 'CouponMultipleHead',
  components: {
    CouponMoreReference: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_item_coupon_more" */'./couponMore/Reference.vue')),
    PlanA: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_item_coupon_plan_a" */ './planA/CouponSwiper.vue')),
    CloseIcon: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/CloseIcon.vue')),
  },
  emits: ['close', 'change-coupon'],
  props: {
    page: Boolean,
    mini: Boolean,
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    couponInfo: {
      type: Object,
      default: () => ({}),
    },
    styleType: {
      type: Number,
      default: null
    },
  },
  computed: {
    couponInfoList() {
      return this.couponInfo?.couponInfos
    },
    componentName() {
      return 'PlanA'
    },
    showCouponMore() {
      return true
    },
    capsuleStyle() {
      return {}
    }
  },
  methods: {
    gotoBack() {
      EventPageBack.notify()
    },
    onChangeCoupon(couponInfo) {
      this.$emit('change-coupon', couponInfo)
    },
    onClickCoupon(data) {
      switch (data.type) {
        case 'add':
        case 'check':
          // swiper.onChangeCoupon 会调用 this.onChangeCoupon
          this.$refs.swiper.onChangeCoupon(data.coupon)
          daEventCenter.triggerNotice({
            daId: '1-8-4-24',
            extraData: {
              position: 'button',
              promotion_code: data.coupon?.couponCode,
              is_satisfied: getSatisfiedValue(data.coupon),
            }
          })
          break
        default:
          console.error('type is invalid')
          break
      }
    }
  },
})
</script>

<style lang="less" scoped>
.multiple-head {
  height: 102/37.5rem;
  overflow: hidden;
  position: relative;
  background-size: cover;
  border-radius: 12/37.5rem 12/37.5rem 0 0;
  background-image: var(--header-background-image, url('https://img.ltwebstatic.com/images3_ccc/2024/03/22/9b/1711103998c93974608b7e7d5f6594292babcc551c.png'));
  background-size: 100% auto;
  &.page {
    height: 132/37.5rem;
    background-image: var(--header-background-image-page, url('https://img.ltwebstatic.com/images3_acp/2024/03/13/33/17102955866b3ce65c49a9afe22c94b36837cbd47a.png'));
    background-position: top;
    border-radius: 0;
    .back {
      height: 28/37.5rem;
      // stylelint-disable-next-line selector-max-specificity
      .icon {
        position: absolute;
        top: 5/37.5rem;
        left: 12/37.5rem;
        z-index: 1;
        line-height: 1;
        font-size: 24/37.5rem;
        color: #fff;
      }
    }
  }
  &.plan-a {
    .header-content__left {
      border-top-right-radius: 12/37.5rem;
      border-bottom-right-radius: 12/37.5rem;
      box-shadow: inset -10px 0 20px -24px #000;
    }
  }
  &.plan-b {
    .header-content__right {
      position: relative;
      z-index: 1;
      box-shadow: -10px 10px 12px -10px #ec5f43;
    }
  }
  .close-icon {
    position: absolute;
    top: 4/37.5rem;
    right: 8/37.5rem;
    z-index: 1;
  }
  &.mini {
    height: auto;
    border-radius: inherit;
    .header-content {
      height: auto;
      &__left {
        border-radius: inherit;
        box-shadow: none;
      }
    }
  }
}
.header-content {
  display: flex;
  align-items: center;
  position: relative;
  height: 102/37.5rem;
  direction: ltr;
  &__left {
    flex: 1;
    height: 100%;
    width: 0;
    overflow: hidden;
  }
  &__right {
    flex-shrink: 0;
    height: 100%;
    width: 60/75rem;
  }
}
</style>
