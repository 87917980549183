/**
 * 凑单弹窗利诱点
 * wiki:1294428702
 */
import schttp from '@/public/src/services/schttp'

/**
 * 利诱
 */
export class IncentivePoint {
  constructor({ abtInfo }) {
    this.countryId = this.getCountryId()
    this.autoUseCoupon = this.getAutoUseCoupon(abtInfo)
    this.lurePointCacheInfo = ''
    this.excludeBubbles = new Set()
    this.abtInfo = abtInfo
  }

  /**
   * 获取购物车凑单弹窗利诱点
   * @param {Object} params - 参数对象
   * @param {string} params.addItemType - 凑单类型
   * @returns {Promise<Object>} 返回API响应
   */
  async getApi({ addItemType }) {
    try {
      const reqData = {
        addItemType,
        countryId: this.countryId,
        autoUseCoupon: this.autoUseCoupon,
        excludeBubbles: Array.from(this.excludeBubbles).join(','),
        lurePointCacheInfo: this.lurePointCacheInfo,
      }
      let res = await schttp({
        url: '/order/cart/add_item_popup/lure_info',
        method: 'POST',
        data: reqData,
        useBffApi: true,
        headers: {
          AppCurrency: window.gbCommonInfo?.currency,
          AppLanguage: window.gbCommonInfo?.appLanguage,
        }
      })
      return res
    } catch {
      return null
    }
  }

  /**
   * 获取标签信息
   * @param {Object} params - 参数对象
   * @param {boolean} params.showBubble - 是否显示气泡
   * @param {string} params.addItemType - 凑单类型
   * @param { Object } params.lureInfo - 可选 外部传入的利诱信息, 若传入则不进行请求
   * @returns {Promise<Object>} 返回标签和气泡信息
   */
  async getTags({ showBubble, addItemType, lureInfo }) {
    let lurePointCacheInfo = null, lurePointInfo = null
    if (lureInfo?.isBff) {
      lurePointCacheInfo = lureInfo?.lureInfo?.lurePointCacheInfo
      lurePointInfo = lureInfo?.lureInfo?.lurePointInfo
    } else {
      const res = await this.getApi({ addItemType })
      lurePointCacheInfo = res?.info?.lurePointCacheInfo
      lurePointInfo = res?.info?.lurePointInfo
    }
    const tags = lurePointInfo?.allLabels ?? []
    const bubble = showBubble ? lurePointInfo?.bubble : null

    bubble && this.excludeBubbles.add(bubble.type)
    this.lurePointCacheInfo = lurePointCacheInfo

    return {
      tags,
      bubble,
    }
  }

  /**
   * 获取自动使用优惠券的状态
   * @returns {number} 返回 1 或 0
   */
  getAutoUseCoupon(abtInfo) {
    const cart_show_coupon_switch = abtInfo?.Cartshowcoupon?.param?.cart_show_coupon_switch || 'off'
    return cart_show_coupon_switch === 'on' ? 1 : 0
  }

  /**
   * 获取国家 ID 
   * @returns {string} 返回国家 ID
   */
  getCountryId() {
    let addressCookie = localStorage.getItem('addressCookie')
    let countryId = ''
    try {
      addressCookie = JSON.parse(addressCookie)
      countryId = addressCookie?.countryId ?? ''
    } catch {
      return countryId
    } 
    return countryId
  }

  /**
   * 销毁缓存数据
   */
  destory() {
    this.excludeBubbles.clear()
    this.lurePointCacheInfo = ''
  }
}
