import { defineAsyncComponent } from 'vue'
import { EventUpdateIncentivePoint } from '../utils/event.js'
import { IncentivePoint } from '../utils/incentivePoint.js'

export default {
  components: {
    IncentivePoint: defineAsyncComponent(() => import(/* webpackChunkName: "incentive_point" */'public/src/pages/common/add-on/comps/incentive/IncentivePoint.vue')),
  },

  data() {
    return {
      // 标签
      tags: [],
      // 气泡
      bubble: {},
      incentivePointInstance: null,
    }
  },

  watch: {
    addItemType: {
      handler(val){
        this.initIncentivePoint({
          addItemType: val,
        })
      },
      immediate: true,
    }
  },

  mounted() {
    window.addEventListener('beforeunload', this.destoryIncentivePoint.bind(this))
  },

  beforeUnmount() {
    this.destoryIncentivePoint()
  },
  
  methods: {
    /**
     * 初始化利诱
     */
    async initIncentivePoint({ addItemType }) {
      this.incentivePointInstance = new IncentivePoint({
        abtInfo: this.abtInfo,
      })

      // 初始化标签数据
      this.tags = (await this.incentivePointInstance.getTags({
        showBubble: false,
        addItemType,
        lureInfo: this.lureInfo,
      })).tags ?? []

      // 监听加购事件
      EventUpdateIncentivePoint.subscribe({
        once: false,
        callback: async ({ showBubble }) => {
          const data = await this.incentivePointInstance.getTags({
            showBubble,
            addItemType,
            lureInfo: this.lureInfo,
          })
          this.tags = data.tags ?? []
          // 线上气泡优先级最大
          if (showBubble) {
            this.bubble = data.bubble ?? {}
          } else {
            this.bubble = {}
          }
        }
      })
    },

    /**
     * 利诱清理工作
     */
    destoryIncentivePoint() {
      // 清除缓存
      this.incentivePointInstance && this.incentivePointInstance.destory()
      this.tags = []
      this.bubble = {}
      // 停止监听事件
      EventUpdateIncentivePoint.remove()
    },
  },
}
