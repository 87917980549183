<template>
  <div
    :class="[
      'c-addItem-list',
      loading && 'skeleton',
    ]"
  >
    <div
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="loadMoreFlag"
      infinite-scroll-nodata="false"
      :infinite-scroll-distance="infiniteScrollDistance"
      class="j-da-event-box"
      data-module="module"
      :da-expose-code="listExposeCode"
      :data-poskey="sa.poskey"
      :data-active-from="sa.activity_from"
    >
      <WaterFall
        v-if="products?.length"
        :items="products"
      >
        <template #default="{ item, index }">
          <WaterFallItem
            :ref="`productListItem_${index}`"
            :item="item"
            :index="index"
            :cur-operate-index="feedbackIndex"
            :card-config="cardConfig"
            :product-item-config="itemConfigV3"
            :constant-data="constantData"
            :language="language"
            :feedback-index="feedbackIndex"
            @long-click="handleFeedbackIndex"
            @open-quick-add="handleAddtobag"
            @click-item="handleClickItem"
            @click-pit-item="handleClickPitItem"
            @click-pit-card="handleClickPitCard"
          />
        </template>
      </WaterFall>

      <s-loading
        :show="loadMoreFlag"
        type="pullup"
        :cur-page-scroll-fix="true"
      />
    </div>

    <!-- 空状态 -->
    <SEmptyStateView
      v-if="!products.length && !loading"
      :content="language.SHEIN_KEY_PWA_20923"
      :button-number="1"
      :button-text="language.SHEIN_KEY_PWA_21081"
      @onButtonClick="$emit('reset')"
    />
    <!-- 骨架屏loading -->
    <div v-show="loading">
      <ListSkeletonV2
        page="addOnItem"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick } from 'vue'
import infiniteScroll from '@sheinfe/vue-infinite-scroll'
import { useStore } from 'vuex'
import WaterFall from '@/public/src/pages/components/product/WaterFall.vue'
import WaterFallItem from './WaterFallItem.vue'
import productItemMixin from '@/public/src/pages/components/product/item_v2/js/mixin'
import ListSkeletonV2 from '@/public/src/pages/product_list_v2/components/ListSkeletonV2/index.vue'
import { SEmptyStateView } from '@shein-aidc/sui-empty-state-view/mobile'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { EventViewTap } from 'public/src/pages/common/add-on/utils/event.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const daEventExpose = daEventCenter.getExposeInstance()
export default defineComponent({
  name: 'AddOnListV3',
  components: {
    WaterFall,
    WaterFallItem,
    ListSkeletonV2,
    SEmptyStateView,
    SLoading,
  },
  directives: {
    infiniteScroll,
  },
  mixins: [productItemMixin],
  props: {
    sa: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    products: {
      type: Array,
      default: () => ([])
    },
    loadMoreFlag: Boolean,
    loading: Boolean,
    itemConfigV3: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { state } = useStore()
    const cardConfig = computed(() => state.cardConfig)
    return {
      cardConfig,
    }
  },
  data() {
    return {
      feedbackIndex: -1,
      listExposeCode: 'addonListExposeCode',
    }
  },
  computed: {
    infiniteScrollDistance() {
      return typeof window !== 'undefined'
        ? 150
        : 10000
    },
  },
  watch: {
    products: {
      handler(val) {
        if (!val?.length) return
        nextTick(() => {
          daEventExpose && daEventExpose.subscribe({
            keycode: `${this.listExposeCode}\`${this.itemConfigV3.itemDAEventExposeId  || ''}|2-10-2`,
          })
        })
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    loadMore() {
      this.$emit('load-more')
    },
    handleFeedbackIndex({ index, isShow }) {
      if(!isShow) {
        return 
      }
      this.feedbackIndex = index
    },
    handleAddtobag({ item = {}, ...args }, cartData) {
      if (cartData?.__cart_item_data__) {
        this.$emit('update-bag', { item, ...args }, cartData)
        return
      }
      this.$emit('add-bag', { emit: 'open-quick-add', item, ...args })
    },
    handleClickItem({ target, index, imgRatio, ...item }, cartData) {
      if (!this.itemConfigV3.disableMainimgJump) {
        EventViewTap.notify({ type: 'CLICK_PRODUCT_ITEM' })
        return
      }
      this.handleAddtobag({ emit: 'click-item', item, target, index, imgRatio }, cartData)
    },
    handleClickPitItem(product, item) {
      this.$emit('click-pit-item', product, item)
    },
    handleClickPitCard(item) {
      this.$emit('click-pit-card', item)
    },
  }
})
</script>

<style lang="less" scoped>
.c-addItem-list {
  padding: 0.16rem 0.16rem 0;
  background-color: #f6f6f6;
  min-height: 100%;
  :deep(.normal-price__content) {
    align-items: baseline;
  }
  :deep(.product-item-ctn) {
    margin-bottom: 0.426rem;
  }

  :deep(.product-card) {
    margin-bottom: 0.16rem;
  }
  :deep(.skeleton-item-goodsDouble){
    padding: 0;
  }

  &.skeleton {
    padding: 0;
    :deep(.list-skeleton-container) {
      padding: 0.16rem 0.16rem 0;
    }
  }
}
</style>
