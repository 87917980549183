<template>
  <div 
    class="full-screen-head"
    :style="style"
  >
    <div class="tips">
      {{ tips }}
    </div>
    <span class="right">
      <slot name="right"></slot>
      <i
        class="suiiconfont sui_icon_close_18px ico"
        @click="handleClose"
      >
      </i>
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { EventClose } from 'public/src/pages/common/add-on/utils/event.js'

const defaultStyle = {
  '--head-bg': `url('/pwa_dist/images/add-on/coupon-addon-header-bg-686159f880.png')`,
  '--tip-color': '#FFF',
  '--tip-text-shadow': 'unset',
  '--close-color': '#fff',
}

export default defineComponent({
  name: 'FullScreenHead',
  props: {
    tips: {
      type: String,
      default: '',
    },
    styleInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    style() {
      let obj = {}
      Object.keys(defaultStyle).forEach((key) => {
        obj[key] = this.styleInfo?.[key] || defaultStyle[key]
      })
      return obj
    }
  },
  methods: {
    handleClose() {
      EventClose.notify()
    }
  },
})
</script>

<style lang="less" scoped>
.full-screen-head {
  height: 36/37.5rem;
  padding: 0 12/37.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: var(--head-bg);
  .tips {
    font-size: 14px;
    margin-right: 12/37.5rem;
    white-space: nowrap;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--tip-color);
    text-shadow: var(--tip-text-shadow)
  }
  .right {
    display: flex;
    align-items: center;
  }
  .ico {
    margin-left: 12/37.5rem;
    font-size: 14px;
    color: var(--close-color);
  }
}
</style>
